import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { IconButton } from "@mui/material";

import { Order_By } from "shared/generated/graphql";

import { Colors } from "../../../theme/colors";

function SortButton({
  currentOrder,
  order,
  orderBy,
  onClick,
}: Readonly<{
  currentOrder: string;
  order: string;
  orderBy: Order_By;
  onClick: (order: string, direction: Order_By) => void;
}>) {
  const isCurrentOrder = currentOrder === order;

  const iconStyle = {
    color: isCurrentOrder ? Colors.Navy : Colors.Grey,
    "&:hover": {
      color: Colors.Navy,
    },
    fontSize: 18,
  };

  return (
    <IconButton
      sx={{ p: 0 }}
      color={isCurrentOrder ? "primary" : "secondary"}
      onClick={() => {
        const newDirection =
          isCurrentOrder && orderBy === Order_By.AscNullsLast
            ? Order_By.DescNullsLast
            : Order_By.AscNullsLast;
        onClick(order, newDirection);
      }}
    >
      {isCurrentOrder && orderBy === Order_By.AscNullsLast ? (
        <ArrowUpwardIcon sx={iconStyle} />
      ) : (
        <ArrowDownwardIcon sx={iconStyle} />
      )}
    </IconButton>
  );
}

export default SortButton;
