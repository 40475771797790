import { Stack, Typography } from "@mui/material";

export default function NeedHelpContactSupportText() {
  return (
    <Stack direction={"row"}>
      <Typography variant="body1">
        <b>Need help?</b> Contact our customer success team at +1 888-788-1372.
      </Typography>
    </Stack>
  );
}
