import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useRouter } from "next/router";

import UnreadMessageBadge from "shared/components/CollapsibleTable/UnreadMessageBadge";
import { generateOrderBy } from "shared/components/CollapsibleTable/utils";
import DataGridTable from "shared/components/DataGridTable/DataGridTable";
import {
  createStyledLinkCell,
  renderTagsCell,
  renderVehicleImageCell,
  useDataGridSortHandler,
} from "shared/components/DataGridTable/utils";
import {
  GetOpenRequestsQuery,
  Order_By,
  useGetOpenRequestsLazyQuery,
  UsersFleetVehicles_Bool_Exp,
} from "shared/generated/graphql";
import { prettyMonthDayYear } from "shared/lib/dates";
import { prettyYearMakeModel } from "shared/lib/utils";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import { WorkflowType } from "../../../../../backend/common/workflow.types";
import NeedHelpContactSupportText from "../../components/NeedHelpContactSupportText";
import { clearServiceStorage } from "../../providers/AccountSettings/storageUtils";
import { useFleets } from "../../providers/FleetProvider";
import { useServices } from "../../providers/ServiceFlowProvider";
import {
  ctaStatuses,
  RequestStatusDisplayVehicleText,
  SaasRequestStatusDisplayVehicleText,
} from "../../services/request.service";

type RequestTableType = GetOpenRequestsQuery["requests"][0];

function getSearchFilters(search: string, fleetIds: number[]) {
  const fleetIdFilter =
    fleetIds.length > 1 ? { _in: fleetIds } : { _eq: fleetIds[0] };

  const baseFilters = [{ deletedAt: { _is_null: true } }];

  if (!search) {
    return {
      fleetId: fleetIdFilter,
      _and: baseFilters,
    };
  }

  const orData: UsersFleetVehicles_Bool_Exp[] = [
    { carNickname: { _ilike: `%${search}%` } },
    { year: { _ilike: `%${search}%` } },
    { make: { _ilike: `%${search}%` } },
    { model: { _ilike: `%${search}%` } },
    { plate: { _ilike: `%${search}%` } },
    { vin: { _ilike: `%${search}%` } },
    { requests: { services: { description: { _ilike: `%${search}%` } } } },
    { requests: { poNumber: { _ilike: `%${search}%` } } },
  ];

  if (!isNaN(+search)) {
    orData.push({ requests: { id: { _eq: parseInt(search, 10) } } });
  }

  return {
    fleetId: fleetIdFilter,
    _and: [...baseFilters, { _or: orData }],
  };
}

export const DataGridOrdersPage = () => {
  const tableApiRef = useGridApiRef();
  const router = useRouter();
  const { getUnreadMessagesCount, setRequestIds } = useUnreadRequests();
  const { currentFleetIds, currentFleetId } = useFleets();
  const { clearServices } = useServices();
  const { sortField, sortDirection, handleSortModelChange } =
    useDataGridSortHandler({
      field: "id",
      direction: Order_By.Desc,
    });

  const [filterModel, setFilterModel] = useState<UsersFleetVehicles_Bool_Exp>(
    {}
  );

  const [getRequests, { loading, data, error }] = useGetOpenRequestsLazyQuery();

  useEffect(() => {
    clearServices();
    clearServiceStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchRequestData() {
      await getRequests({
        fetchPolicy: "network-only",
        variables: {
          fleetIds: currentFleetIds,
          orderBy: generateOrderBy(sortField, sortDirection),
          filter: filterModel,
        },
      });

      const allRequestIds = data?.requests.flatMap(
        (request) => request.id ?? []
      );
      if (!allRequestIds) return;
      setRequestIds(allRequestIds);
    }
    if (!currentFleetIds?.length) return;
    fetchRequestData();
  }, [
    currentFleetIds,
    sortField,
    sortDirection,
    filterModel,
    getRequests,
    data?.requests,
    setRequestIds,
  ]);

  const renderStatusCell = useCallback(
    (
      params: GridRenderCellParams<{
        status: {
          requestId: RequestTableType["id"];
          workflowType: RequestTableType["workflowType"];
          requestStatus: RequestTableType["status"];
        };
      }>
    ) => {
      const isSU360 = params.value.workflowType === WorkflowType.ServiceUp;
      const statusText = isSU360
        ? RequestStatusDisplayVehicleText.get(params.value.requestStatus ?? "")
        : SaasRequestStatusDisplayVehicleText.get(
            params.value.requestStatus ?? ""
          );

      const commonStyles = {
        maxWidth: { xs: "160px", sm: "100%" },
      };

      return ctaStatuses?.includes(params.value.requestStatus) ? (
        <Button
          variant="contained"
          size="small"
          sx={commonStyles}
          href={`request/${params.value.requestId}?fleetId=${currentFleetId}`}
        >
          {statusText}
        </Button>
      ) : (
        <Chip
          variant="outlined"
          size="small"
          label={statusText}
          sx={commonStyles}
        />
      );
    },
    [currentFleetId]
  );

  const columns: GridColDef<(typeof rows)[0]>[] = useMemo(() => {
    return [
      {
        field: "image",
        headerName: "",
        sortable: false,
        disableExport: true,
        renderCell: renderVehicleImageCell,
      },
      {
        field: "id",
        headerName: "Order",
        hideable: false,
        renderCell: (params) => {
          const LinkCell = createStyledLinkCell(
            (params) => `request/${params.value}?fleetId=${currentFleetId}`
          );
          return (
            <UnreadMessageBadge
              content={LinkCell(params)}
              unreadMessageCount={getUnreadMessagesCount(params.value)}
            />
          );
        },
      },
      { field: "shop.name", headerName: "Shop" },
      { field: "fleet.name", headerName: "Fleet" },
      { field: "usersFleetVehicles.carNickname", headerName: "Name" },
      { field: "vehicle.year", headerName: "YMM" },
      { field: "vehicle.plate", headerName: "License Plate" },
      { field: "vehicle.vin", headerName: "VIN" },
      { field: "poNumber", headerName: "PO#" },
      { field: "createdAt", headerName: "Requested Date" },
      { field: "customerEcd", headerName: "ECD" },
      {
        field: "vehicleTags",
        headerName: "Tags",
        renderCell: renderTagsCell,
        sortable: false,
        disableExport: true,
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 170,
        renderCell: renderStatusCell,
      },
    ];
  }, [currentFleetId, getUnreadMessagesCount, renderStatusCell]);

  const rows =
    data?.requests.map((request: RequestTableType) => {
      const vehicle = request.usersFleetVehicles?.vehicle;
      return {
        image: {
          vehicleId: request.usersFleetVehicles?.vehicleId,
          imageUrl: request.usersFleetVehicles?.imageUrl,
        },
        id: request.id,
        "shop.name":
          request.workflowType === WorkflowType.SAAS
            ? request.shop?.name
            : "ServiceUp",
        "fleet.name": request.fleet?.name,
        "usersFleetVehicles.carNickname":
          request.usersFleetVehicles?.carNickname,
        "vehicle.year": prettyYearMakeModel(
          vehicle?.year,
          vehicle?.make,
          vehicle?.model
        ),
        "vehicle.plate": vehicle?.plate,
        "vehicle.vin": vehicle?.vin,
        createdAt: prettyMonthDayYear(request?.createdAt),
        customerEcd: prettyMonthDayYear(request?.customerECD, true),
        poNumber: request.poNumber,
        status: {
          requestId: request.id,
          requestStatus: request.status,
          workflowType: request.workflowType,
        },
        vehicleTags: {
          tags: request.usersFleetVehicles?.vehicleTags
            .slice(0, 2)
            .map((tag) => tag.tag),
        },
      };
    }) ?? [];

  const handleFilterModelChange = useCallback(
    (gridFilterModel: GridFilterModel) => {
      if (!currentFleetIds) return;
      const searchTerm = gridFilterModel?.quickFilterValues?.[0];
      const updatedQuickFilter = getSearchFilters(searchTerm, currentFleetIds);

      setFilterModel(updatedQuickFilter);
    },
    [currentFleetIds]
  );

  return (
    <Box p={3} mb={4} mt={2}>
      <Typography variant="h6" mb={2}>
        Orders
      </Typography>

      {!error ? (
        <DataGridTable
          apiRef={tableApiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          handleSortChange={handleSortModelChange}
          toolbarFilter={{ handleFilterChange: handleFilterModelChange }}
          pinnedColumns={{ right: ["status"] }}
        />
      ) : (
        <Box mt={10}>
          <Stack spacing={1}>
            <Box display={"flex"} justifyContent={"center"}>
              <Typography variant="h3" textAlign={"center"}>
                We were unable to retrieve your requests
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Typography variant="body1" textAlign={"center"}>
                Please&nbsp;
                <Button
                  onClick={() => router.reload()}
                  sx={{ px: 0.2, mt: -0.3 }}
                >
                  <Typography
                    sx={{ textDecoration: "underline" }}
                    variant="body1"
                    textAlign={"center"}
                  >
                    refresh
                  </Typography>
                </Button>
                &nbsp;the page or logout and log back in.
              </Typography>
            </Box>
          </Stack>
          <Box mt={10} display={"flex"} justifyContent={"center"}>
            <NeedHelpContactSupportText />
          </Box>
        </Box>
      )}
    </Box>
  );
};
