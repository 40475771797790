import { Badge, Box } from "@mui/material";

const UnreadMessageBadge = ({
  content,
  unreadMessageCount,
}: {
  content: string | number | JSX.Element;
  unreadMessageCount: number;
}) => {
  return (
    <Box display={"flex"} alignItems={"flex-start"}>
      <Box mr={1} sx={{ marginTop: "10px" }}>
        {content}
      </Box>
      <Box mr={1}>
        <Badge
          badgeContent={unreadMessageCount}
          max={99}
          invisible={unreadMessageCount === 0}
        />
      </Box>
    </Box>
  );
};

export default UnreadMessageBadge;
