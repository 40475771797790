import {
  Box,
  SxProps,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { Order_By } from "../../../generated/graphql";

import SortButton from "./SortButton";
import { TableHeader } from "./types";

function TableHeadWithSort({
  headers,
  sortField,
  sortDirection,
  onSortClick,
  cellSx,
  textSx,
}: Readonly<{
  headers: TableHeader[];
  sortField: string;
  sortDirection: Order_By;
  onSortClick: (sortField?: string, sortDirection?: Order_By) => void;
  cellSx?: SxProps;
  textSx?: SxProps;
}>) {
  return (
    <TableHead>
      <TableRow>
        {headers.map((column) => {
          return (
            <TableCell key={column.label} sx={cellSx}>
              <Box display={"flex"} width={column.width} gap={0.5}>
                <Typography fontSize={12} sx={textSx}>
                  {column.label}
                </Typography>
                {column.isSortable && (
                  <SortButton
                    currentOrder={sortField}
                    order={column.fieldName}
                    orderBy={sortDirection}
                    onClick={onSortClick}
                  />
                )}
              </Box>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default TableHeadWithSort;
