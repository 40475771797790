const removeFromStorage = (storageName: string): void => {
  window.localStorage.removeItem(storageName);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const clearServiceStorage = () => {
  removeFromStorage("approvedServices");
  removeFromStorage("addOnCategories");
  removeFromStorage("serviceFlowStatus");
  removeFromStorage("validCategories");
  removeFromStorage("services");
  removeFromStorage("vehicle");
  removeFromStorage("images");
};
